import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, redirect } from "react-router-dom";
import Cookies from "js-cookie";

const DimenticatoPassword = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventDetails,
    setEventDetails,
  } = useContext(StateContext);

  const [isError, setIsError] = useState(false);
  const [successoInvioMail, setSuccessoInvioMail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  const loginUrl = new URL(window.location.href);
  const eventCode = loginUrl.searchParams.get("eventCode");
  const autologinCode = loginUrl.searchParams.get("autologinCode");

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_URL}/evento/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        const eventDetails = response.data.response;
        Cookies.set("eventDetails", JSON.stringify(eventDetails));
        setEventDetails(eventDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (event) => {
    setSuccessoInvioMail(false);
    setIsError("");
    event.preventDefault();
    setLoading(true);
    const loginPayload = {
      email: email || "",
      eventCode: eventCode || "",
    };

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/new-password-email`,
        loginPayload
      )
      .then(
        (response) => {
          if (response.data.error) {
            setIsError(response.data.error);
          } else {
            console.log(response.data.response);
            setLoading(false);
            setIsError("");
            setSuccessoInvioMail(response.data.response.mail_inviata);
          }
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.error(reason.response.data);
          if (reason.response.data.code === 401) {
            setIsError("Email o password errati");
          } else if (reason.response.data.code === 404) {
            setIsError(reason.response.data.error);
          } else {
            setIsError("C'è stato un errore nel server. Riprovare più tardi.");
          }
        }
      );
  };

  return (
    <div
      className="account-pages"
      style={{
        height: "auto",
        backgroundColor: eventDetails.colorePrimarioEvento,
        minHeight: "100vh",
      }}
    >
      <div
        className="container-fluid"
        style={{
          background: `url(${
            (eventDetails && eventDetails.coverEvento) || ""
          }) center center / cover no-repeat`,
          height: "auto",
          minHeight: "50vh",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${(eventDetails && eventDetails.logoEvento) || ""}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <div className="box-nome-evento" style={{borderColor: eventDetails.colorePrimarioEvento,}}>
              {(eventDetails && eventDetails.nomeEvento) || "-"}
            </div>
            {successoInvioMail ? (
              <div className=" my-5">
                <h5 className="fw-bold text-white">E-mail inviata</h5>
                <p className="text-white">
                  Se l'indirizzo email inserito è corretto, riceverai un
                  messaggio con le istruzioni per il cambio password.
                </p>
              </div>
            ) : (
              <div className="">
                <p className="my-5 text-white">
                  Conferma la tua e-mail per ricevere il link di cambio password
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label text-white" htmlFor="username">
                      E-mail
                    </label>
                    <input
                      type="text"
                      className="form-control  bg-transparent input-login-stellantis"
                      id="username"
                      placeholder="Inserisci la tua e-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="mt-5 text-end d-flex align-items-center justify-content-between">
                    <button
                      className={`btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis py-3 ${
                        email === "" ? "disabled" : ""
                      }`}
                      type="submit"
                      style={{color: eventDetails.colorePrimarioEvento,}}
                    >
                      Conferma
                    </button>
                  </div>
                  {isError && (
                    <div
                      class="alert alert-danger text-black mt-3 text-center"
                      role="alert"
                    >
                      {isError}
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DimenticatoPassword;
