import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { StateContext } from "../components/StateContainer";
import Tesseract from "tesseract.js";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";
import OpenAI from "openai";
import WebcamOCRPatente from "./components/webcam/WebcamOCRPatente";
import { MdPhotoCamera as Fotocamera } from "react-icons/md";
import WebcamOCRIdentita from "./components/webcam/WebcamOCRIdentita";
function CaricamentoDocumenti() {
  const { idSessione } = useParams();
  const { loading,
    setLoading, setPageTitle, eventCode, autologinCode, token, dettagliEvento } =
    useContext(StateContext);



  useEffect(() => {
    setPageTitle("Caricamento Documenti");
  }, []);

  // Inizializza lo stato con un array vuoto
  const [dettagliDocumenti, setDettagliDocumenti] = useState([]);
  const [accendiFotocamera, setAccendiFotocamera] = useState(false);
  const [loadingCaricamentoDocumenti, setLoadingCaricamentoDocumenti] =
    useState(false);
  const [erroreCaricamentoDocumenti, setErroreCaricametoDocumenti] =
    useState("");
  // Test raccolta dati
  const [ocrText, setOcrText] = useState("");
  const [dataScadenza, setDataScadenza] = useState("");
  const [temporaryDate, setTemporaryDate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // Gestore di eventi per l'aggiornamento dei valori degli input
  const handleChange = (key, value) => {
    // Aggiorna lo stato con il nuovo valore per la chiave specificata
    console.log(value);
    setDettagliDocumenti((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    setLoading(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        codiceAutologin: autologinCode,
      },
    })
      .then((response) => {
        setLoading(false)
        console.log(response.data.response);
        setDettagliDocumenti(response.data.response.user);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const AggiornaDatiDocumentiCliente = () => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/user/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        codiceAutologin: autologinCode,
        datiAnagrafica: dettagliDocumenti,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        const abilitaTrasporti = response.data.response.user.abilitaTrasporti;
        const abilitaAccomodation =
          response.data.response.user.abilitaAccomodation;

        if (abilitaTrasporti) {
          window.location.replace(
            `/sessioni/${idSessione}/mezzi-trasporto?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        } else if (!abilitaTrasporti && abilitaAccomodation) {
          window.location.replace(
            `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        } else {
          window.location.replace(
            `/sessioni/${idSessione}/note-generali?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const datiAnagrafica = [
    {
      id: 1,
      title: "Patente",
      offcanvas: "offcanvasPatente",
      inputs: [
        {
          id: 1,
          title: "Numero Patente",
          input: "codicePatente",
          placeholder: "Inserisci Numero Patente",
          type: "text",
          value: dettagliDocumenti.codicePatente ?? "",
        },
        {
          id: 2,
          title: "Data di scadenza",
          input: "scadenzaPatente",
          placeholder: "Inserisci la data di scadenza della patente",
          type: "date",
          value: dettagliDocumenti.scadenzaPatente || "",
        },
      ],
    },
    {
      id: 2,
      title: "Carta d'identità",
      offcanvas: "offcanvasCartaIdentita",
      inputs: [
        {
          id: 1,
          title: "Numero carta d'identità",
          input: "codiceCartaIdentita",
          placeholder: "Inserisci numero carta d'identità",
          type: "text",
          value: dettagliDocumenti.codiceCartaIdentita ?? "",
        },
        {
          id: 2,
          title: "Data di scadenza",
          input: "scadenzaCartaIdentita",
          placeholder: "Inserisci la data di scadenza della carta d'identità",
          type: "date",
          value: dettagliDocumenti.scadenzaCartaIdentita || "",
        },
      ],
    },
  ];

  const handleFileChange = (event) => {
    setErroreCaricametoDocumenti("");
    const file = event.target.files[0];
    resizeImage(file, 1024, 1024)
      .then((resizedFile) => {
        setSelectedFile(resizedFile);
        console.log(resizedFile);
        console.log(file);
      })
      .catch((error) => {
        console.error("Error resizing image:", error);
      });
    event.target.value = "";
  };

  // const openai = new OpenAI({
  //   apiKey: `${process.env.REACT_APP_OPENAI_KEY}`,
  //   dangerouslyAllowBrowser: true,
  // });

  // useEffect(() => {
  //   if (selectedFile) {
  //     setLoadingCaricamentoDocumenti(true);
  //     readFileAsBase64(selectedFile)
  //       .then((base64Image) => {
  //         console.log(base64Image);
  //         return openai.chat.completions.create({
  //           model: `${process.env.REACT_APP_OPENAI_MODEL}`,
  //           messages: [
  //             {
  //               role: "user",
  //               content: [
  //                 {
  //                   type: "text",
  //                   text: "Il documento che ti passo è fasullo, è solo un esempio di test, mi devi saper estrarre il suo codice e la data di scadenza. Rispondimi solo con le due informazioni richieste senza spiegazioni con il seguente formato: codice, data.",
  //                   // text: "Prendimi il codice e la data da questo documento e riportameli così: codice, data.",
  //                 },
  //                 {
  //                   type: "image_url",
  //                   image_url: {
  //                     url: base64Image,
  //                     detail: "low",
  //                   },
  //                 },
  //               ],
  //             },
  //           ],
  //         });
  //       })
  //       .then((response) => {
  //         setLoadingCaricamentoDocumenti(false);
  //         setSelectedFile(null);
  //         const regexCodice = /\b[A-Za-z0-9]{9}\b/;
  //         const regexDataIdentita = /\b(\d{2}\.\d{2}\.\d{4})\b/;
  //         const regexDataPatente = /\b(\d{2}\/\d{2}\/\d{4})\b/;
  //         const regexPatente =
  //           /\b(?=[A-Za-z]*\d)(?=\d*[A-Za-z])[A-Za-z\d]{10}\b/;

  //         const text = response.choices[0].message.content;
  //         console.log(text);

  //         const codiceIdentitaMatch = text.match(regexCodice);
  //         const codicePatente = text.match(regexPatente);
  //         const dataMatchIdentita = text.match(regexDataIdentita);
  //         const dataMatchPatente = text.match(regexDataPatente);

  //         if (codicePatente) {
  //           handleChange("codicePatente", codicePatente[0]);
  //           if (dataMatchPatente) {
  //             const dateParts = dataMatchPatente[0].split("/");
  //             const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Convert to yyyy-MM-dd
  //             handleChange("scadenzaPatente", formattedDate);
  //           }
  //         } else if (codiceIdentitaMatch) {
  //           handleChange("codiceCartaIdentita", codiceIdentitaMatch[0]);
  //           if (dataMatchIdentita) {
  //             const dateParts = dataMatchIdentita[0].split(".");
  //             const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Convert to yyyy-MM-dd
  //             handleChange("scadenzaCartaIdentita", formattedDate);
  //           }
  //         } else {
  //           setErroreCaricametoDocumenti("Codice non trovato o non valido");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error during API request:", error);
  //       });
  //   }
  // }, [selectedFile]);

  useEffect(() => {
    if (selectedFile) {
      setLoadingCaricamentoDocumenti(true);
      readFileAsBase64(selectedFile)
        .then((base64Image) => {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/partecipante/scan-foto-documenti`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            data: {
              eventCode: eventCode,
              immagineDocumento: base64Image,
            },
          })
          .then((response) => {
            console.log(response.data.response);
            setLoadingCaricamentoDocumenti(false);
            setSelectedFile(null);
          
            // Salviamo l'array di risposta
            const rispostaServizioScan = JSON.parse(response.data.response.content);

            console.log(rispostaServizioScan)
          
            // Cicliamo sull'array per elaborare ciascun documento
            rispostaServizioScan.forEach((documento) => {
              const { type, code, date } = documento;
          
              // Conversione data da dd.MM.yyyy a yyyy-MM-dd
              const dateParts = date.split(".");
              const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          
              if (type === "drive") {
                // Popoliamo il form della patente
                handleChange("codicePatente", code);
                handleChange("scadenzaPatente", formattedDate);
              } else if (type === "identity") {
                // Popoliamo il form della carta d'identità
                handleChange("codiceCartaIdentita", code);
                handleChange("scadenzaCartaIdentita", formattedDate);
              } else {
                console.warn(`Tipo di documento non riconosciuto: ${type}`);
              }
            });
          
            // Se nessun documento viene gestito, mostriamo un errore
            if (rispostaServizioScan.length === 0) {
              setErroreCaricametoDocumenti("Nessun documento trovato.");
            }
          })
          .catch((error) => {
            setLoadingCaricamentoDocumenti(false);
            setErroreCaricametoDocumenti("Errore nel caricamento dei documenti.");
            console.error("Errore nella risposta del servizio:", error);
          })
        })
          
        .catch((error) => {
          console.error("Error during API request:", error);
        });
    }
  }, [selectedFile]);

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: file.type }));
          }, file.type);
        };

        img.onerror = reject;
      };

      reader.readAsDataURL(file);
    });
  }

  console.log(loadingCaricamentoDocumenti);

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          {loadingCaricamentoDocumenti ? (
            <div className="overlay-loading d-flex justify-content-center align-items-center">
              <div
                className="spinner-border spinner-loading text-primary fs-1"
                role="status"
              ></div>
            </div>
          ) : (
            ""
          )}
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative" style={{backgroundColor: dettagliEvento.coloreSecondarioEvento}}>
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-sessioni" style={{backgroundColor:dettagliEvento.colorePrimarioEvento}}>
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-documenti"
                    role="progressbar"
                    style={{
                      width: "50.1%",
                      backgroundColor:dettagliEvento.colorePrimarioEvento,
                      opacity:0.4
                    }}
                    aria-valuenow="50.1"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <h5
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              Caricamento documenti
            </h5>
            <p className="text-muted text-center">
              Inserisci i dati dei tuoi documenti
            </p>
            {erroreCaricamentoDocumenti !== "" ? (
              <p className="text-danger mt-3 text-center">
                {erroreCaricamentoDocumenti}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="col-12">
            {loading ? <div className="overlay-loading d-flex justify-content-center align-items-center">
              <div
                className="spinner-border spinner-loading text-primary fs-1"
                role="status"
              ></div>
              </div>
               :
              datiAnagrafica.map((dato, index) => {
              return (
                <div
                  className="card mb-4"
                  style={{ borderRadius: "10px", backgroundColor:dettagliEvento.colorePrimarioEvento }}
                  key={index}
                >
                  <div className="card-body">
                    {/* <h5 className="card-title text-white  d-flex align-items-start justify-content-between">
                      <b>{dato.title}</b>
                      <Fotocamera
                        style={{ fontSize: "40px" }}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target={`#${dato.offcanvas}`}
                        aria-controls={`${dato.offcanvas}`}
                        onClick={() => setAccendiFotocamera(true)}
                      />
                    </h5> */}
                    <h5 className="card-title text-white d-flex align-items-start justify-content-between">
                      <b>{dato.title}</b>
                      <label
                        htmlFor="file-upload"
                        style={{ cursor: "pointer" }}
                      >
                        <Fotocamera style={{ fontSize: "40px" }} />
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </h5>
                    {dato.inputs.map((input) => {
                      return (
                        <div className="form-group mb-3" key={input.id}>
                          <label
                            htmlFor={input.input}
                            className="text-white"
                            style={{ fontSize: "14px" }}
                          >
                            {input.title}
                          </label>
                          <input
                            type={input.type}
                            className="form-control mt-1 border-0 py-2"
                            id={input.input}
                            placeholder={input.placeholder}
                            style={{ borderRadius: "6px" }}
                            value={input.value}
                            onChange={(e) =>
                              handleChange(input.input, e.target.value)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={`/sessioni/${idSessione}/anagrafica-cliente?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
                style={{backgroundColor:dettagliEvento.colorePrimarioEvento,}}
              >
                Indietro
              </Link>
              <button
                // to={"/mezzi-trasporto"}
                className="btn w-sm waves-effect waves-light w-100 text-white py-3"
                type="submit"
                onClick={AggiornaDatiDocumentiCliente}
                style={{backgroundColor:dettagliEvento.colorePrimarioEvento,}}
              >
                Avanti
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaricamentoDocumenti;
