import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Loading from "./components/loading/Loading";

function Ringraziamento() {
  const {
    error,
    setError,
    setPageTitle,
    dettagliEvento,
    eventCode,
    autologinCode,
    token,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle("Ringraziamento");
  }, []);

  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setLoading(false);
        console.log(response.data.response);
        setDettagliPartecipazione(response.data.response);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
        console.error("sono ", error);
      });
  }, []);

  return (
    <div
      className="account-pages"
      style={{
        height: "auto",
        minHeight: "100vh",
        backgroundColor:dettagliEvento.colorePrimarioEvento,
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            minHeight: "100vh",
            height: "auto",
          }}
        >
         <div className="col-12 p-0">
            <div className="box-settings p-5" style={{backgroundColor:dettagliEvento.coloreSecondarioEvento, borderColor: dettagliEvento.colorePrimarioEvento}}>
              <img src={`${dettagliEvento.logoEvento}`} alt="" className="responsive-image" />
            </div>
          </div>

          {loading ? (
            <div className="col-12 text-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="col-12 p-0 text-center mb-5">
                <h1
                  className="text-center text-white mt-5 mb-5"
                  style={{ fontWeight: "lighter", fontSize: "25pt" }}
                >
                  {dettagliEvento.nomeEvento}
                </h1>
              </div>
              <div className="col-12 mt-5">
                <div
                  className=" mt-5 mb-5 text-white text-center"
                  style={{ fontSize: "23px" }}
                >
                  {dettagliPartecipazione.stato === "iscritto" ? (
                    <>
                      <p>
                        Grazie per essersi registrato,
                        <br />a breve riceverà una mail di
                        <br />
                        conferma con il riepilogo dei dati
                        <br />
                        inseriti.
                      </p>
                      <Link
                        to={`/qr-code?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                        className="btn bg-white w-75 py-3 mt-4 mb-5"
                        style={{color:dettagliEvento.colorePrimarioEvento,}}
                      >
                        Visualizza QR CODE
                      </Link>
                    </>
                  ) : dettagliPartecipazione.stato === "nonPartecipa" ? (
                    <p>
                      La sua partecipazione è stata
                      <br />
                      rifiutata correttamente.
                    </p>
                  ) : dettagliPartecipazione.stato === "overbooking" ? (
                    <p>
                      La sua partecipazione è in overbooking.
                      <br />
                      Sarà avvisato in caso di disponibilità.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Ringraziamento;
