import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { StateContext } from "../components/StateContainer";
import { Link } from "react-router-dom";
function NonPartecipoPage() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    token,
    dettagliEvento,
    eventCode,
    autologinCode,
  } = useContext(StateContext);

  const [noteRifiuto, setNoteRifiuto] = useState("");

  useEffect(() => {
    setPageTitle("Non partecipo");
  }, []);

  const PartecipazioneRifiutata = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/rifiutata`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        rifiuto_partecipazione: true,
        note: noteRifiuto,
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(response);
        window.location.replace(
          `/ringraziamento?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  return (
    <div
      className="account-pages"
      style={{
        height: "auto",
        minHeight: "100vh",
        backgroundColor: dettagliEvento.colorePrimarioEvento
      }}
    >
      <div
        className="container-fluid img-cover-evento"
        style={{
          background: `url(${dettagliEvento.coverEvento}) center center / cover no-repeat`,
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${dettagliEvento.logoEvento}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <h1
              className="text-center text-white mt-5 mb-5"
              style={{ fontWeight: "lighter", fontSize: "25pt" }}
            >
              NON PARTECIPO
            </h1>
            <div class="form-group">
              <label
                for="textAreaNonPartecipo"
                className="text-white"
                style={{ fontSize: "15px" }}
              >
                Motivare la richiesta di cambio data
              </label>
              <textarea
                className="form-control mt-3"
                id="textAreaNonPartecipo"
                rows="15"
                value={noteRifiuto}
                onChange={(e) => setNoteRifiuto(e.target.value)}
              ></textarea>
            </div>
            <div className="mt-5 text-end d-flex align-items-center justify-content-between mb-5">
              <Link
                to={`/conferma-partecipazione?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                className="btn bg-white w-sm waves-effect waves-light w-100 me-2 py-3"
                type="submit"
                style={{color:dettagliEvento.colorePrimarioEvento}}
              >
                Indietro
              </Link>
              <button
                className="btn bg-white w-sm waves-effect waves-light w-100 ms-2 py-3"
                type="submit"
                onClick={PartecipazioneRifiutata}
                style={{color:dettagliEvento.colorePrimarioEvento}}
              >
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonPartecipoPage;
