import React, { useContext } from "react";
import { FiLogOut as LogoutIcon } from "react-icons/fi";
import useAuth from "../../../UseAuth";
import axios from "axios";
import Cookies from "js-cookie";
import { StateContext } from "../../../components/StateContainer";

const ConfermaNonPartecipazione = () => {
    const {
        setPageTitle,
        eventCode,
        autologinCode,
        dettagliEvento,
        token,
        setError,
      } = useContext(StateContext);

  let modals = document.getElementsByClassName("modal");
  let modalsBackdrop = document.getElementsByClassName("modal-backdrop");


  const PartecipazioneRifiutata = () => {
    for (let i = 0; i < modals.length; i++) {
        modals[i].classList.remove("show");
      }
    for (let i = 0; i < modalsBackdrop.length; i++) {
        modalsBackdrop[i].classList.remove("show");
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/rifiutata`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        rifiuto_partecipazione: true,
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        window.location.replace(
          `/non-partecipo?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  return (
    <>
      <div
        className="logout"
        data-bs-toggle="modal"
        data-bs-target="#nonPartecipoModal"
        style={{ cursor: "pointer" }}
      >
      </div>

      <div
        className="modal fade"
        id="nonPartecipoModal"
        tabIndex="-1"
        aria-labelledby="nonPartecipoModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content border-0 overflow-hidden"
            style={{ borderRadius: "10px 10px 10px 10px" }}
          >
            <div className="modal-header border-0 pb-0">
              <h1
                className="modal-title fs-5"
                id="nonPartecipoModal"
                style={{color:dettagliEvento.colorePrimarioEvento}}
              >
                <b className="d-flex align-items-center gap-2">
                  Non partecipo
                </b>
              </h1>
            </div>
            <div className="modal-body py-0">
              Confermi di non voler partecipare all'evento?
            </div>
            <div className="d-flex align-items-center overflow-hidden">
              <button
                type="button"
                className="btn text-black bg-grey-stellantis w-50 rounded-0"
                data-bs-dismiss="modal"
              >
                Annulla
              </button>
              <button
                type="button"
                className="btn text-white w-50 bg-danger rounded-0"
                onClick={PartecipazioneRifiutata}
              >
                Confermo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfermaNonPartecipazione;
