import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import { StateContext } from "../components/StateContainer";
import axios from "axios";
import ConfermaNonPartecipazione from "../Hostess/components/non-partecipazione/ConfermaNonPartecipazione";
import { Link } from "react-router-dom";
function ConfermaPartecipazione() {
  const {
    setPageTitle,
    eventCode,
    autologinCode,
    dettagliEvento,
    token,
    setError,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle("Conferma partecipazione");
  }, []);

  const [descrizioneEvento, setDescrizioneEvento] = useState("");

  useEffect(() => {
    setError("");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipante/evento/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data && response.data.error) {
          setError(response.data.error);
        } else {
          console.log(response);

          setDescrizioneEvento(response.data.response.descrizioneEvento);
        }
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const PartecipazioneRifiutata = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/rifiutata`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        rifiuto_partecipazione: true,
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        window.location.replace(
          `/non-partecipo?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  return (
    <div
      className="account-pages"
      style={{
        height: "auto",
        minHeight: "100vh",
        background: dettagliEvento.colorePrimarioEvento
      }}
    >
      <div
        className="container-fluid img-cover-evento"
        style={{
          background: `url(${dettagliEvento.coverEvento}) center center / cover no-repeat`,
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${dettagliEvento.logoEvento}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <div className="box-nome-evento" style={{borderColor: dettagliEvento.colorePrimarioEvento}}>{dettagliEvento.nomeEvento}</div>
            <div className="text-white mt-4" style={{ flexGrow: "1" }}>
              <p dangerouslySetInnerHTML={{ __html: descrizioneEvento }}></p>
            </div>
            <div className="mt-5">
              <form>
                <div className="mt-5 mb-5">
                  <Link
                    to={`/partecipo?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                    className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis py-4 mb-4"
                    style={{ fontSize: "24px", color:dettagliEvento.colorePrimarioEvento }}
                    type="button"
                  >
                    PARTECIPO
                  </Link>
                  <button
                    className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis py-4"
                    style={{ fontSize: "24px", color:dettagliEvento.colorePrimarioEvento, cursor: "pointer" }}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#nonPartecipoModal"
                  >
                    NON PARTECIPO
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ConfermaNonPartecipazione/>
    </div>
  );
}

export default ConfermaPartecipazione;
